// JS Global Compulsory
import '../src/javascripts/jquery-global';
import 'jquery-migrate';
import 'bootstrap/dist/js/bootstrap';

// JS Rails
import Rails from 'rails-ujs';
Rails.start();

// JS Implementing Plugins
import '../src/javascripts/vendor/hs-megamenu/hs.megamenu';
import '../src/javascripts/vendor/fancybox/jquery.fancybox';
import '../src/javascripts/typed-global';

// JS Front
import '../src/javascripts/hs.core';
import '../src/javascripts/components/hs.header';
import '../src/javascripts/components/hs.unfold';
import '../src/javascripts/components/hs.fancybox';
import '../src/javascripts/components/hs.go-to';

// JS App
import '../src/javascripts/app';
import '../src/javascripts/floating-wpp.min';
