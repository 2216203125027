$(window).on('load', function () {
  // initialization of HSMegaMenu component
  $('.js-mega-menu').HSMegaMenu({
    event: 'hover',
    pageContainer: $('.container'),
    breakpoint: 767.98,
    hideTimeOut: 0
  });
});

$(document).on('ready', function () {
  // initialization of header
  $.HSCore.components.HSHeader.init($('#header'));

  // initialization of unfold component
  $.HSCore.components.HSUnfold.init($('[data-unfold-target]'), {
    afterOpen: function () {
      $(this).find('input[type="search"]').focus();
    }
  });

  // initialization of fancybox
  $.HSCore.components.HSFancyBox.init('.js-fancybox');

  // initialization of go to
  $.HSCore.components.HSGoTo.init('.js-go-to');

  // scroll animation of know more button in index
  $('#btn-know-more').on('click', function() {

    $('html, body').animate({
      scrollTop: $('#heading-value-offer').offset().top - 75
    }, 1000);
  });
});